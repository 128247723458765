<template>
  <div class="viewcontent">
    <h1>{{ translations.title_error_pay }}</h1>
    <p>{{ translations.error_pay }}</p>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'PayError',
    computed: {
      ...mapState(['translations']),
    },
  }
</script>

<style></style>
